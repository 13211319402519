import { App, Plugin } from "vue";
import keycloak from "../keycloak.config";

export const KeycloakPlugin: Plugin = {
  install: async (app: App) => {
    app.config.globalProperties.$keycloak = keycloak;
    try {
      const authenticated = await keycloak.init({
        onLoad: "check-sso",
        scope: "openid",
        silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
      });
      console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);

      } catch (error) {
          console.log('Failed to initialize adapter:', error);
      }
  },
};
