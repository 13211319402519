import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home-content" }
const _hoisted_2 = { class: "overlay_2" }
const _hoisted_3 = { class: "conten" }
const _hoisted_4 = {
  key: 0,
  class: "service"
}
const _hoisted_5 = { class: "overlay_1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_newPlant = _resolveComponent("newPlant")!
  const _component_selectedPrincipal = _resolveComponent("selectedPrincipal")!
  const _component_selectedPrincipalTest = _resolveComponent("selectedPrincipalTest")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.permissions.some((evt) => evt.id === 3))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_RouterLink, { to: "/planta" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("message.home.btn1")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.permissions.some((evt) => evt.id === 4))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "service-btn",
              id: "btn-abrir-popup",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopupPrincipal = true))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("message.home.btn2")), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.permissions.some((evt) => evt.id === 5))
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "btn-monitoring",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopupPrincipalTest = true))
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t("message.home.btn3")), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.showPopup)
        ? (_openBlock(), _createBlock(_component_newPlant, {
            key: 0,
            style: {"z-index":"5"},
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closePopup()))
          }))
        : _createCommentVNode("", true),
      (_ctx.showPopupPrincipal)
        ? (_openBlock(), _createBlock(_component_selectedPrincipal, {
            key: 1,
            style: {"z-index":"5"},
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closePopup())),
            onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirmPopupPrincipal()))
          }))
        : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createElementVNode("div", {
        style: {"z-index":"1"},
        class: "mapa"
      }, null, -1))
    ]),
    (_ctx.showPopupPrincipalTest)
      ? (_openBlock(), _createBlock(_component_selectedPrincipalTest, {
          key: 0,
          style: {"z-index":"5"},
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closePopupTest())),
          onConfirm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.confirmPopupPrincipalTest()))
        }))
      : _createCommentVNode("", true)
  ]))
}