<template>
  <div>
    <router-view name="header" />
    <router-view v-if="!isLoading"/>
    <div class="isLoading" v-else>
      <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
      animationDuration=".5s" aria-label="Loading" v-if="isLoading" />
    </div>
    <router-view name="background"/>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
    getToken, getTokenRefresh,removeToken
} from '@/utils/auth'
import store from './store'
import ProgressSpinner from 'primevue/progressspinner';
import { mapState } from "vuex";
import router from "@/router";
export default defineComponent({
  name: "servicios-leo",
  components: {
    ProgressSpinner,
  },
  mounted () {
    if (getToken() && getTokenRefresh()) {
      store.dispatch("GET_USER_INFO",{"type": 2, "endpoint": 2})
             .then(response => {
              // this.$router.push('/home')
              store.dispatch('showToast', {
              message: "Sesion Recuperada",
              type: "success"
              })
              // this.$router.push('/home')
              })
             .catch(error =>{
              removeToken();
           router.push('/')
                 console.log('error', error)});
    }
   
  },
  computed: {
    ...mapState({
      isLoading: (state: any) => state.AUTH.isLoading,
      user: (state: any) => state.AUTH.user
    }),
  },
});
</script>

<style lang="scss">
@import './assets/css/normaliza.css';
body{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  
}

.isLoading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
