import request from "@/utils/aplication";
const obj = {
  getDataConfig(values: any) {
    return request({
      url: "/application",
      method: "post",
      data: values,
    });
  },
  functionConfig(values: any) {
    return request({
      url: "/function",
      method: "post",
      data: values,
    });
  },
  // uploadFile(values: any) {
  //   return request({
  //     url: "/application/formData",
  //     method: "post",
  //     data: values,
  //   });
  // },
  getUserInfo(values: any) {
    return request({
      url: "/orchestatorv3/userData",
      method: "post",
      data: values,
    });
  },
  getTerminalsInfo(values: any) {
    return request({
      url: "/orchestatorv3/dataList",
      method: "post",
      data: values,
    });
  },
  getApiCallMap(values: any) {
    return request({
      url: "/orchestatorv2/orchestator/apiCall",
      method: "post",
      data: values,
    });
  },
};
export default obj;
