import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "isLoading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view, { name: "header" }),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                key: 0,
                style: {"width":"50px","height":"50px"},
                strokeWidth: "8",
                fill: "transparent",
                animationDuration: ".5s",
                "aria-label": "Loading"
              }))
            : _createCommentVNode("", true)
        ])),
    _createVNode(_component_router_view, { name: "background" })
  ]))
}