import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_3 = { class: "popup-form" }
const _hoisted_4 = { class: "group" }
const _hoisted_5 = { class: "group" }
const _hoisted_6 = { class: "btn-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          style: {"cursor":"pointer","margin-top":"-1rem"},
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('message.planta.f31')), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_select, {
              modelValue: _ctx.principalId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.principalId) = $event)),
              options: _ctx.principal,
              label: "name",
              required: "",
              placeholder: _ctx.$t('message.planta.f31'),
              class: "custom-select"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_select, {
              modelValue: _ctx.accountSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.accountSelected) = $event)),
              options: _ctx.accounts,
              label: "countryName",
              required: "",
              placeholder: "country",
              class: "custom-select"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn-submit-address",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.confirmarPopup && _ctx.confirmarPopup(...args)))
            }, _toDisplayString(_ctx.$t('message.planta.f32')), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}