import request from '@/utils/grafana'
const obj = {
  getMonitoringUrl(values: any) {
    return request({
      url: '/create-snapshot/grafanaleo/showterminal',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrlEN(values: any) {
    return request({
      url: '/create-snapshot/grafanaleo/showterminalEN',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrlPT(values: any) {
    return request({
      url: '/create-snapshot/grafanaleo/showterminalPT',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrlPrincipal(values: any) {
    return request({
      url: '/create-snapshot/grafanaleo/generalstarlink',
      method: 'post',
      data: values
    })
  },
}
export default obj