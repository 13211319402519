import request from '@/utils/mail-nest'
const obj = {
  sendmailnest(values:any) {
    return request({
      url: '/v1/application/send-email',
      method: 'post',
      data: values
    })
  },

  downloadFile(values: any) {
    return request({
      url: '/v1/application/dowload-file',
      method: 'post',
      data: values,
      responseType: 'arraybuffer'
    })
  },

  uploadFile(data:any) {
    return request({
      url: '/v1/application/upload-file',
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  
  // uploadFile(data: FormData) {
  //   return request({
  //     url: '/v1/application/upload-file',
  //     method: 'post',
  //     data: data,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });
  // }

  // uploadFile(values: any) {
  //   return request({
  //     url: '/v1/application/upload-file',
  //     method: 'post',
  //     data: values
  //   })
  // },

  // uploadFile(formData: FormData) {
  //   return request({
  //     url: '/v1/application/upload-file',
  //     method: 'post',
  //     data: formData,
  //   });
  // },

  

}
export default obj