import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import primeVue from 'primevue/config';
import { createI18n } from 'vue-i18n';
import { getLocale} from "@/utils/auth";
import { KeycloakPlugin } from "./plugins/keycloak";
const messages = {
    en: {
      message: {
        language:'en',
        export:'Export',
        title: 'Leo Services',
        auth:{
          logout:"Log out",
          login: "Login",
          f1:"Username",
          f2:"Password",
          f3:"Sign In",
          f4:"edit profile",
          f5:"support",
          f6:"Forgot Password?",
          f7:"Reset password",
          f8:"Go back"
        },
        home:{
          btn1:"Device List",
          btn2:"New Service",
          btn3:"Monitoring"
        },
        planta:{
          search:"Search",
          c1:"Customer",
          c2:"Site ID",
          c3:"Suscriptor Name",
          c4:"Comercial Status",
          c5:"Technical Status",
          c6:"Serial Number",
          c7:"Last connection",
          c8:"Daily Use (L.C.)",
          c9:"Cycle use GB",
          c10:"Service Plan",
          c11:"Address",
          c12:"Long",
          c13:"Lat",
          c14:"Options",
          op1:"Commissioning",
          op2:"Update Service Plan",
          op3:"Update Address",
          op4:"Suspend Service",
          op5:"Cancel Service",
          op6:"Support",
          op7:"Monitoring",
          f1:"New Service",
          f2:"Address",
          f3:"Verify Address",
          f4:"Update Address",
          f5:"Site ID",
          f6:"select service plan",
          f7:"select serial number",
          f8:"New Service",
          f9:"Commissioning",
          f10:"Select File",
          f11:"Upload File",
          f12:"Update Service Plan",
          f13:"Select service plan",
          f14:"Update Service Plan",
          f15:"Long",
          f16:"Lat",
          f17:"New Address",
          f18:"Update Address",
          f19:"Cancel Service",
          f20:"Do you really want to cancel the service git",
          f21:"Cancel Service",
          f22:"Support",
          f23:"Contact Name",
          f24:"Contact e-mail",
          f25:"select Problem",
          f26:"Description",
          f27:"Send Ticket",
          f28:"Suspend Service",
          f29:"Do you really want to suspend the service ",
          f30:"Suspend Service",
          f31:"select a principal",
          f32:"confirm",
          b01:"Deregistration carried out successfully",
          b02:"It is no longer listed on the plant list",
          b03:"Released and available to assign to another service",
          b04:"will continue to be billed until the next day 5",
          b05:"Service",
          b06:"The service",
          m01:"MONTHLY CONSUMPTION: ",
          m02:"Active",
          m03:"Inactive", 
          m04:"Select Contact Type",
          m05:"Enter Customer",
          m06:"Select Media Communication",
          m07:"Select the request type"

        },
        profile:{
          pr01:"Welcome",
          pr02:"User",
          pr03:"Full Name",
          pr04:"Password",
          pr05:"Confirm Password"
        }

      }
    },
    es: {
      message: {
        language:'es',
        export:'Exportar',
        title: 'Servicios LEO',
        auth:{
          logout:"salir",
          login: "Iniciar sesión",
          f1:"Usuario",
          f2:"Contraseña",
          f3:"Ingresar",
          f4:"editar perfil",
          f5:"soporte",
          f6:"¿Has olvidado tu contraseña?",
          f7:"resetear contraseña",
          f8:"volver"
        },
        home:{
          btn1:"Planta",
          btn2:"Nuevo Servicio",
          btn3:"Monitoreo"
        },
        planta:{
          search:"Ingrese para realizar la busqueda…",
          c1:"Cliente",
          c2:"Site Id",
          c3:"Nombre Suscritor",
          c4:"Estado comercial",
          c5:"Estado Técnico",
          c6:"Numero de Serie",
          c7:"última conexión",
          c8:"consumo diario (U.C.)",
          c9:"Consumo de ciclo GB",
          c10:"Plan de Servicio",
          c11:"Dirección",
          c12:"Longitud",
          c13:"Latitud",
          c14:"Acción",
          op1:"Comisionamiento",
          op2:"Cambio de plan",
          op3:"Cambio de Dirección",
          op4:"Suspender",
          op5:"Baja Total",
          op6:"Soporte",
          op7:"Tráfico y consumo",
          f1:"Nuevo Servicio",
          f2:"Dirección",
          f3:"Verificar Dirección",
          f4:"Cambiar Dirección",
          f5:"Site Id",
          f6:"Seleccionar Plan de Servicio",
          f7:"Seleccionar Serial Number",
          f8:"Nuevo Servicio",
          f9:"Comisionamiento",
          f10:"Elegir archivo",
          f11:"Cargar Archivo",
          f12:"Cambio de plan",
          f13:"Seleccionar plan de servicio",
          f14:"Cambio de plan",
          f15:"Longitud",
          f16:"Latitud",
          f17:"Nueva Dirección",
          f18:"Cambiar Dirección",
          f19:"Baja Total",
          f20:"¿REALMENTE DESEA DAR DE BAJA TOTAL SU SERVICIO",
          f21:"Baja Total",
          f22:"Soporte",
          f23:"Nombre de contacto",
          f24:"Correo de contacto",
          f25:"Tipo de Problema",
          f26:"Descripción",
          f27:"Generar Ticket",
          f28:"SUSPENDER",
          f29:"¿REALMENTE DESEA SUSPENDER SU SERVICIO ",
          f30:"SUSPENDER",
          f31:"selecciona un principal",
          f32:"confirmar",
          b01:"Baja realizada de manera exitosa",
          b02:"Ya no figura en el listado de planta",
          b03:"Liberado y disponible para asignar a otro servicio",
          b04:"se seguira facturando hasta el próximo día 5",
          b05:"Servicio",
          b06:"El servicio",
          m01:"CONSUMO DEL MES: ",
          m02:"Activo",
          m03:"Inactivo",
          m04:"Selecciona tipo de contacto",
          m05:"Ingresa el Cliente",
          m06:"Selecciona la Media Comunicacion",
          m07:"Selecciona el Tipo de solicitud"
        },
        profile:{
          pr01:"Bienvenido",
          pr02:"Usuario",
          pr03:"Nombre Completo",
          pr04:"Contraseña",
          pr05:"Confirmar Contraseña"
        }
      }
    },
    pt: {
      message: {
        language:'pt',
        export:'Exportar',
        title: 'Serviços LEO',
        auth:{
          logout:"Sair",
          login: "Iniciar sessão",
          f1:"Usuário",
          f2:"Senha",
          f3:"Entrar",
          f4:"editar perfil",
          f5:"suporte",
          f6:"Esqueceu sua senha?",
          f7:"redefinir senha",
          f8:"volte"
        },
        home:{
          btn1:"Lista de Dispositivos",
          btn2:"Novos Serviços",
          btn3:"Monitoramento"
        },
        planta:{
          search:"Buscar…",
          c1:"Cliente",
          c2:"Site ID",
          c3:"Nome do Cliente",
          c4:"Status Comercial",
          c5:"Status Técnico",
          c6:"Número de Série",
          c7:"Última Conexão",
          c8:"Consumo Diário (UC)",
          c9:"Consumo do Ciclo GB",
          c10:"Plano contratado",
          c11:"Endereço",
          c12:"Longitude",
          c13:"Latitude",
          c14:"Ações",
          op1:"Comissionamento",
          op2:"Atualização do Plano contratado ",
          op3:"Atualização Endereço",
          op4:"Suspender Serviço",
          op5:"Cancelar Serviço",
          op6:"Suporte",
          op7:"Monitoramento",
          f1:"NOVO SERVIÇO",
          f2:"Endereço",
          f3:"Verificar o Endereço",
          f4:"Atualização Endereço",
          f5:"Site ID",
          f6:"Selecionar Plano contratado",
          f7:"Selecionar Número de Série",
          f8:"Novo Serviço",
          f9:"COMISSIONAMENTO",
          f10:"Selecionar Arquivo",
          f11:"Carregar Arquivo",
          f12:"ATUALIZAR PLANO DE SERVIÇO",
          f13:"Selecionar Arquivo",
          f14:"Atualização do Plano contratado ",
          f15:"Longitud",
          f16:"Latitud",
          f17:"Novo Endereço",
          f18:"Atualização Endereço",
          f19:"CANCELAR SERVIÇO",
          f20:"Cancelar o serviço ",
          f21:"Cancelar Serviço",
          f22:"APOIAR",
          f23:"Nome de Contato",
          f24:"Email de Contato",
          f25:"Selecione o Problema",
          f26:"Descrição",
          f27:"Enviar Ticket",
          f28:"SUSPENDER SERVIÇO",
          f29:"Deseja suspender o serviço ",
          f30:"Suspender Serviço",
          f31:"selecione um principal",
          f32:"confirme",
          b01:"Cancelamento de registro realizado com sucesso",
          b02:"Não está mais listado na lista de plantas",
          b03:"Liberado e disponível para atribuir a outro serviço",
          b04:"continuará a ser cobrado até o próximo dia 5",
          b05:"Serviço",
          b06:"O serviço",
          m01:"CONSUMO DO MÊS: ",
          m02:"Ativo",
          m03:"Inativo",
          m04:"Selecione o tipo de contato",
          m05:"Login do cliente",
          m06:"Selecione Comunicação de Mídia",
          m07:"Selecione o tipo de aplicativo"
        },
        profile:{
          pr01:"Bem-vindo",
          pr02:"Usuário",
          pr03:"Nome completo",
          pr04:"Senha",
          pr05:"Confirmar senha"
        }
      }
    }
  }
  const i18n = createI18n({
    locale: getLocale() ? getLocale() : 'en',
    fallbackLocale: 'en',
    warnHtmlInMessage: "off",
    messages
})
createApp(App).use(store).use(router).use(primeVue).use(KeycloakPlugin).use(i18n).mount('#app')
