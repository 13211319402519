import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/candado.svg'
import _imports_1 from '@/assets/forgot.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "login-container",
  id: "login-container"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "username" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "forgot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        alt: "candado"
      }, null, -1)),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.auth.f6')), 1),
      (_ctx.modeForm==='login')
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            style: {"width":"90%"},
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.recover(_ctx.data)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('message.auth.f1')), 1),
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.email) = $event)),
                class: "input-login",
                type: "text",
                id: "username",
                placeholder: "Ingresa tu email"
              }, null, 512), [
                [_vModelText, _ctx.data.email]
              ])
            ]),
            _createElementVNode("button", {
              disabled: _ctx.isLoading,
              type: "submit",
              id: "submit",
              class: "btn-submit",
              value: ""
            }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    style: {"width":"25px","height":"25px"},
                    strokeWidth: "8",
                    fill: "transparent",
                    animationDuration: ".5s",
                    "aria-label": "Loading"
                  }))
                : (_openBlock(), _createElementBlock("strong", _hoisted_6, _toDisplayString(_ctx.$t('message.auth.f7')), 1))
            ], 8, _hoisted_5)
          ], 32))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('message.auth.f8')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", null, [
      _createElementVNode("img", {
        src: _imports_1,
        alt: "forgot"
      })
    ], -1))
  ]))
}