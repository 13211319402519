<template>
    <div class="content-body">
        <forgot />
    </div>
  </template>
  <style lang="scss" scoped>
  .content-body {
    height: calc(100vh - 107px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  
    button {
      margin-top: 40px;
      background: rgba(0, 102, 255, 0.25);
      border: 2px solid rgba(0, 102, 255, 0.25);
      border-radius: 10px;
      height: 41px;
      width: 279px;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  
    button:hover {
      background-color: #2001b9fe;
      // border: 1px solid #bbbbbb;
      border: 2px solid #ffffff;
      border-radius: 10px;
      font-weight: bold;
      height: 41px;
      width: 279px;
      color: #ffffff;
    }
  }
  </style>
  <script lang="ts">
  import { defineComponent } from "vue";
  import Tierra from "@/components/planeta/planeta.vue";
  import store from "../../store";
  import { mapState } from "vuex";
  import router from "@/router";
  import forgot from "@/components/form/forgotForm.vue"
  export default defineComponent({
    name: "landing",
    components: {
      Tierra,
      forgot
    },
    mounted() {
        console.log('ok');
      if (this.user) {
        router.push('/home');
      };
    },
    computed: {
      ...mapState({
        user: (state: any) => state.AUTH.user,
      }),
    },
    methods: {
      enviarCorreo() {
        var data = {
          body: "Consultar Cobertura",
          asunto: "Consultar Cobertura",
          correo_destino: "Elvis.shahuano@wydsoluciones.com",
        };
        store
          .dispatch("SEND_MAIL", data)
          .then((response) => {
            store.dispatch("showToast", {
              message: "CORREO ENVIADO EXITOSAMENTE",
              type: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      },
    },
  });
  </script>
  