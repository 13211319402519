<template>
    <Popup>
      <div class="header-content">
      <img
        @click="closePopup"
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
      />
      <h1
        style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        ">
          {{$t('message.planta.b01')}}
        </h1>
     
      </div>

      <div class="poppup-body">
        <p>{{$t('message.planta.b05')}}  {{terminalSelected.terminals_nickname}} {{$t('message.planta.b02')}}</p> 
        <p>{{terminalSelected.kitSerialNumber}} {{$t('message.planta.b03')}}</p>
        <p>{{$t('message.planta.b06')}} {{terminalSelected.terminals_nickname}} {{$t('message.planta.b04')}}</p> 
      </div>
  
  
    </Popup>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import Popup from "../../../components/planta-conte/Popup.vue";
  import store from "../../../store";
  import "@/assets/sass/StylesPlanta.scss";
  import { mapState, mapGetters } from "vuex";
  
  export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
      Popup,
    },
    computed: {
      ...mapState({
        user: (state: any) => state.AUTH.user,
        token: (state: any) => state.AUTH.token
      }),
      ...mapGetters(["permissions"]),
  
    },
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
      closePopup() {
        this.$emit("close");
      },
    },
  });
  </script>
  <style lang="scss">
  .dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
  }
  
  .dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
  }
  
  .dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
  }
  
  .dropdown-content a:hover {
    background-color: #0079ff;
  }
  
  .btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
  }
  
  .btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
  }
  
  .custom-select .vs__dropdown-toggle {
    background-color: white;
  }
  
  .custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .poppup-body p{
    color: #ffffff;
  }
  </style>
  