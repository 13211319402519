<template>
  <div class="container">
    <div @click.prevent="inizializa()" id="world-map" class="world">
      <div id="mapa" class="mapa"></div>
    </div>
    <div class="login-container" id="login-container">
      <h2>{{ $t('message.auth.login')}}</h2>
     <form v-if="modeForm==='login'" @submit.prevent="login(user)"> 
         <div class="form-group">
          <label for="username">{{ $t('message.auth.f1')}}</label>
          <input
            required
            v-model="user.username"
            class="input-login"
            type="text"
            id="username"
            placeholder="Ingresa tu usuario"
          />
        </div>
        <div class="form-group">
          <label for="password">{{ $t('message.auth.f2')}}</label>
          <input
            required
            v-model="user.password"
            class="input-login"
            type="password"
            id="password"
            placeholder="Ingresa tu contraseña"
          />
        </div> 
     
         <button :disabled="isLoading" type="submit" id="submit" class="btn-submit" value="">
          <ProgressSpinner style="width: 25px; height: 25px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Loading" v-if="isLoading" />
          <strong v-else>
            {{ $t('message.auth.f3')}}
          </strong>
          
        </button> 
       </form>
       <!-- <div class="forgot">
          <router-link to="/forgot-password">{{ $t('message.auth.f6')}} </router-link>
        </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import store from '../../store/index'
import router from "@/router";
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: "VideoBackground",
  data() {
    return {
      user:{
        username: "",
        password:"",
      },
      newuser:{
        email: "",
        fullname:"",
        password:"",
        confirm_password:"",
      },
      modeForm:"login",
    }
  },
  components: {
    ProgressSpinner,
  },
  mounted() {
      if (this.userauth) {
        router.push('/home')
      }
  },
  computed: {
    ...mapState({
      userauth: (state: any) => state.AUTH.user,
      isLoading: (state: any) => state.AUTH.isLoadingInter,
    }),
  },
  methods: {
    inizializa: () => {
      var dom = document.getElementById("world-map");
      var dom2 = document.getElementById("login-container");
      if (dom!=null && dom2!=null) {
        dom.className += " animate";
        dom2.className += " login-aparece";
        setTimeout(() => {
          if (dom2!=null) {
            dom2.className += " aparece";
          }
      }, 1000);
      }
      
     
   
    },
    login(values: any){
        store.dispatch('LOGIN', values)
             .then(response => response.text())
             .then(result => {
              console.log(result)
             })
             .catch(error =>{
                 console.log('error', error)});
    },
  },
});
</script>
<style scoped>
@keyframes aparece {
  from {
    margin-right: 0px;
  }
  to {
    margin-right: 290px;
  }
}

@keyframes aparece-login {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (min-width: 1549px) {
  .input-login {
    width: 100%;
    max-width: 282px;
    padding: 8px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 4px;
    margin-right: 5px; /* Agregado */
  }
  .login-aparece {
    animation: aparece-login 2s linear;
  }
  .animate {
    animation: aparece 1s linear;
  }
  .aparece {
    display: block !important;
  }

  .mapa {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    cursor: pointer;
    height: 520px;
   
    border-radius: 50%;
    background: url(@/assets/12MAP.jpeg);
    background-size: cover;
    box-shadow: inset 0 0 20px #000, inset -40px -10px 20px rgba(3, 32, 56, 0.7),
      inset 5px 5px 0 0 rgba(255, 255, 255, 0.2);

    animation: tierra-girando 44s infinite linear;
  }

  @keyframes tierra-girando {
    0% {
      background-position: 0px center;
    }
    25% {
      background-position: 900px center;
    }
    50% {
      background-position: 1800px center;
    }
    75% {
      background-position: 2700px center;
    }
    100% {
      background-position: 3600px center;
    }
  }

  .popup.active {
    transform: translateX(50%);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 187px;
    width: 100%;
  
  }
  .login-container {
    width: 300px;
    margin: 0 auto;
    padding: 35px;
    background-color: rgba(2, 89, 189, 0.5);
    border-radius: 5px;
    margin-left: 12rem;
    display: none;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #ffffff;
  }

  .btn-submit {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0057b8;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  .btn-submit:hover {
    background-color: #2001b9fe;
    border: 1px solid #bbbbbb;
  }

  .swal2-popup {
    background-color: rgba(0, 0, 0, 0.8) !important;
    background-color: #007bff;
  }
}
.forgot{
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}
.forgot a{
    margin-top: 20px;
    color: #fff;
    font-weight: bold;
}
</style>
