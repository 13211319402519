<template>
    <div class="notificacion-content">
        <labelCard></labelCard>
    </div>
</template>

<script>
import labelCard from './label.vue'
    export default {
        name:"notification-component",
        components: {
            labelCard,
        },
    }
</script>

<style lang="scss" scoped>

</style>