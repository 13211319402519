import request from '@/utils/flask'
const obj = {
    insertFile(data: any) {
        return request({
          url: '/api/insert-upload-file',
          method: 'post',
          data: data,
        })
      },
}

export default obj