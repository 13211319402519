import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'
import _imports_1 from '@/assets/Vector-servicio.png'
import _imports_2 from '@/assets/Vector-search.png'
import _imports_3 from '@/assets/menu.png'
import _imports_4 from '@/assets/checkbox-circle.svg'
import _imports_5 from '@/assets/note-edit.svg'
import _imports_6 from '@/assets/trash.svg'
import _imports_7 from '@/assets/handshake.svg'
import _imports_8 from '@/assets/signal.svg'


const _hoisted_1 = { class: "header-close" }
const _hoisted_2 = { class: "header-content" }
const _hoisted_3 = { style: {"color":"white","text-align":"center"} }
const _hoisted_4 = { class: "file-upload-container" }
const _hoisted_5 = {
  for: "file-upload",
  class: "file-upload-label"
}
const _hoisted_6 = { class: "btn-content" }
const _hoisted_7 = {
  type: "submit",
  class: "btn-submit",
  id: "btn-cerrar-popup2",
  style: {"margin-top":"0rem","margin-left":"1rem","margin-bottom":"2rem"}
}
const _hoisted_8 = { class: "table-container" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "header-close" }
const _hoisted_11 = { class: "header-content" }
const _hoisted_12 = { style: {"color":"white"} }
const _hoisted_13 = {
  class: "popup-form",
  action: ""
}
const _hoisted_14 = { class: "group" }
const _hoisted_15 = {
  key: 0,
  class: "btn-content"
}
const _hoisted_16 = {
  key: 1,
  class: "spinner-content2"
}
const _hoisted_17 = { class: "header-close" }
const _hoisted_18 = { class: "header-content" }
const _hoisted_19 = { style: {"color":"white"} }
const _hoisted_20 = { class: "popup-form" }
const _hoisted_21 = { class: "group" }
const _hoisted_22 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_23 = { class: "btn-content" }
const _hoisted_24 = { class: "header-close" }
const _hoisted_25 = { class: "header-content" }
const _hoisted_26 = { style: {"color":"white"} }
const _hoisted_27 = { class: "group" }
const _hoisted_28 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_29 = { class: "btn-content" }
const _hoisted_30 = { class: "header-close" }
const _hoisted_31 = { class: "header-content" }
const _hoisted_32 = { style: {"color":"white"} }
const _hoisted_33 = { class: "group" }
const _hoisted_34 = { class: "group" }
const _hoisted_35 = ["placeholder"]
const _hoisted_36 = { class: "group" }
const _hoisted_37 = { class: "group" }
const _hoisted_38 = { class: "group" }
const _hoisted_39 = ["placeholder"]
const _hoisted_40 = { class: "btn-content" }
const _hoisted_41 = {
  class: "btn-submit",
  type: "submit",
  id: "btn-cerrar-popup2",
  style: {"margin-top":"1rem","margin-left":"1rem"}
}
const _hoisted_42 = {
  style: {"z-index":"1"},
  class: "table-content"
}
const _hoisted_43 = { class: "slider-container" }
const _hoisted_44 = { style: { color: 'blue', marginRight: '10px', fontSize: '13px' } }
const _hoisted_45 = { style: { color: 'red', marginRight: '10px', fontSize: '13px' } }
const _hoisted_46 = { style: { color: 'blue', fontSize: '13px' } }
const _hoisted_47 = { class: "buscar-content" }
const _hoisted_48 = {
  style: {"margin-right":"0px !important"},
  class: "buscar-servicio"
}
const _hoisted_49 = ["placeholder"]
const _hoisted_50 = { class: "btn-content" }
const _hoisted_51 = ["onClick"]
const _hoisted_52 = ["onMouseleave"]
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { class: "flex flex-column align" }
const _hoisted_55 = { class: "flexing-menu" }
const _hoisted_56 = { class: "font-bold" }
const _hoisted_57 = ["onClick"]
const _hoisted_58 = { class: "flex flex-column align" }
const _hoisted_59 = { class: "flexing-menu" }
const _hoisted_60 = { class: "font-bold" }
const _hoisted_61 = ["onClick"]
const _hoisted_62 = { class: "flex flex-column align" }
const _hoisted_63 = { class: "flexing-menu" }
const _hoisted_64 = { class: "font-bold" }
const _hoisted_65 = ["onClick"]
const _hoisted_66 = { class: "flex flex-column align" }
const _hoisted_67 = { class: "flexing-menu" }
const _hoisted_68 = { class: "font-bold" }
const _hoisted_69 = ["onClick"]
const _hoisted_70 = { class: "flex flex-column align" }
const _hoisted_71 = { class: "flexing-menu" }
const _hoisted_72 = { class: "font-bold" }
const _hoisted_73 = ["onClick"]
const _hoisted_74 = { class: "flex flex-column align" }
const _hoisted_75 = { class: "flexing-menu" }
const _hoisted_76 = { class: "font-bold" }
const _hoisted_77 = ["onClick"]
const _hoisted_78 = { class: "flex flex-column align" }
const _hoisted_79 = { class: "flexing-menu" }
const _hoisted_80 = { class: "font-bold" }
const _hoisted_81 = {
  key: 1,
  class: "spinner-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PopupComi = _resolveComponent("PopupComi")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_PopupDrop = _resolveComponent("PopupDrop")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_selectedPrincipal = _resolveComponent("selectedPrincipal")!
  const _component_newPlant = _resolveComponent("newPlant")!
  const _component_ChangeAdrees = _resolveComponent("ChangeAdrees")!
  const _component_BajaValidation = _resolveComponent("BajaValidation")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showPopupDrowComisionamiento && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupComi, {
          key: 0,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                style: {"cursor":"pointer"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("message.planta.f9")) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname), 1)
            ]),
            (_ctx.permissions.some((evt) => evt.id === 12))
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  class: "popup-form",
                  onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cargarArchivo && _ctx.cargarArchivo(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("input", {
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
                      type: "file",
                      id: "file-upload",
                      class: "file-upload-input",
                      ref: "fileInput",
                      name: "file"
                    }, null, 544),
                    _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("message.planta.f10")), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("button", _hoisted_7, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f11")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_DataTable, {
                        value: _ctx.commisioning,
                        class: "table-upload-file",
                        style: {"width":"100%","height":"100%"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "id",
                            header: "ID"
                          }),
                          _createVNode(_component_Column, {
                            field: "terminalId",
                            header: "terminalId"
                          }),
                          _createVNode(_component_Column, {
                            field: "name",
                            header: "Name",
                            class: "name-column"
                          }),
                          _createVNode(_component_Column, {
                            field: "url",
                            header: "URL"
                          }, {
                            body: _withCtx(({ data }) => [
                              _createElementVNode("a", {
                                onClick: _withModifiers(($event: any) => (_ctx.downloadFile(data)), ["prevent"]),
                                class: "download-link"
                              }, "Descargar", 8, _hoisted_9)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ])
                  ])
                ], 32))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrow && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 1,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t("message.planta.f12")) + " " + _toDisplayString(String(_ctx.terminalSelected.terminals_nickname).toUpperCase()), 1)
            ]),
            _createElementVNode("form", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.newService.planservice,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newService.planservice) = $event)),
                  options: _ctx.myPlansSelected,
                  label: "name",
                  placeholder: _ctx.$t('message.planta.f13'),
                  class: "custom-select"
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]),
              (!_ctx.isLoadingUpdateServicePlan)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("button", {
                      onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.chagePlanService(_ctx.terminalSelected, _ctx.newService)), ["prevent"])),
                      class: "btn-submit",
                      type: "submit",
                      id: "btn-cerrar-popup2",
                      style: {"margin-top":"1rem","margin-left":"1rem"}
                    }, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f14")), 1)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_ProgressSpinner, {
                      style: {"width":"50px","height":"50px"},
                      strokeWidth: "8",
                      fill: "transparent",
                      animationDuration: ".5s",
                      "aria-label": "Custom ProgressSpinner"
                    })
                  ]))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowSus && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 2,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("h1", _hoisted_19, _toDisplayString(_ctx.$t("message.planta.f28")), 1)
            ]),
            _createElementVNode("form", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("h3", _hoisted_22, _toDisplayString(_ctx.$t("message.planta.f29")) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname.toUpperCase()) + " ? ", 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("button", {
                  onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.suspender(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-submit",
                  type: "submit",
                  id: "btn-cerrar-popup2",
                  style: {"margin-left":"1rem"}
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f30")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowBaja && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_PopupDrop, {
          key: 3,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("h1", _hoisted_26, _toDisplayString(_ctx.$t("message.planta.f19")), 1)
            ]),
            _createElementVNode("form", {
              class: "popup-form",
              action: "",
              onSubmit: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.confirmSubmitBaja && _ctx.confirmSubmitBaja(...args)))
            }, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("h3", _hoisted_28, _toDisplayString(_ctx.$t("message.planta.f20")) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname.toUpperCase()) + " ? ", 1)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("button", {
                  onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.bajaTotal(_ctx.terminalSelected)), ["prevent"])),
                  class: "btn-submit",
                  type: "submit",
                  id: "btn-cerrar-popup2",
                  style: {"margin-left":"1rem"}
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f21")), 1)
                ])
              ])
            ], 32)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupDrowSoporte && _ctx.terminalSelected)
      ? (_openBlock(), _createBlock(_component_Popup, {
          key: 4,
          style: {"z-index":"5"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("img", {
                style: {"cursor":"pointer","margin-top":"-1rem"},
                width: "25",
                height: "25",
                src: _imports_0,
                alt: "delete-sign--v1",
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("h2", _hoisted_32, _toDisplayString(_ctx.$t("message.planta.f22")) + " " + _toDisplayString(_ctx.terminalSelected.nickname), 1)
            ]),
            _createElementVNode("form", {
              class: "popup-form",
              onSubmit: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_ctx.generarTicket(_ctx.ticketSoporte, _ctx.terminalSelected)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.typeContact,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ticketSoporte.typeContact) = $event)),
                  options: _ctx.problemtypes.dataTypeContactId,
                  label: "name",
                  placeholder: _ctx.$t('message.planta.m04'),
                  class: "custom-select"
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_34, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.ticketSoporte.dataTypeContact) = $event)),
                  class: "custom-input",
                  type: "text",
                  placeholder: _ctx.$t('message.planta.m05')
                }, null, 8, _hoisted_35), [
                  [_vModelText, _ctx.ticketSoporte.dataTypeContact]
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.mediaComunication,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.ticketSoporte.mediaComunication) = $event)),
                  options: _ctx.problemtypes.dataMediaComunicationId,
                  label: "name",
                  placeholder: _ctx.$t('message.planta.m06'),
                  class: "custom-select"
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.ticketSoporte.request,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.ticketSoporte.request) = $event)),
                  options: _ctx.problemtypes.dataPinProblem,
                  label: "name",
                  placeholder: _ctx.$t('message.planta.m07'),
                  class: "custom-select"
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_38, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.ticketSoporte.descripcion) = $event)),
                  class: "custom-input",
                  type: "",
                  placeholder: _ctx.$t('message.planta.f26'),
                  style: {"padding-left":"0.5rem","width":"318px","height":"87px"},
                  name: "",
                  id: "",
                  cols: "30",
                  rows: "10"
                }, null, 8, _hoisted_39), [
                  [_vModelText, _ctx.ticketSoporte.descripcion]
                ])
              ]),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("button", _hoisted_41, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.f27")), 1)
                ])
              ])
            ], 32)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupPrincipal)
      ? (_openBlock(), _createBlock(_component_selectedPrincipal, {
          key: 5,
          style: {"z-index":"5"},
          onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.closePopup())),
          onConfirm: _cache[19] || (_cache[19] = ($event: any) => (_ctx.confirmPopupPrincipal()))
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_newPlant, {
          key: 6,
          style: {"z-index":"5"},
          onClose: _cache[20] || (_cache[20] = ($event: any) => (_ctx.closePopup()))
        }))
      : _createCommentVNode("", true),
    (_ctx.showPopupAddress)
      ? (_openBlock(), _createBlock(_component_ChangeAdrees, {
          key: 7,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[21] || (_cache[21] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    (_ctx.showPopupBajaValidation)
      ? (_openBlock(), _createBlock(_component_BajaValidation, {
          key: 8,
          style: {"z-index":"5"},
          terminalSelected: _ctx.terminalSelected,
          onClose: _cache[22] || (_cache[22] = ($event: any) => (_ctx.closePopup()))
        }, null, 8, ["terminalSelected"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_42, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", {
          ref: "slider",
          class: "slider",
          onAnimationiteration: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.handleAnimationIteration && _ctx.handleAnimationIteration(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slider, (column, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "message success",
              key: index
            }, [
              _createVNode(_component_Message, {
                severity: "warn",
                closable: false
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_44, "SITE ID: " + _toDisplayString(column.terminals_nickname), 1),
                  _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("message.planta.m01") + column.porcentaje + "%"), 1),
                  _createElementVNode("span", _hoisted_46, _toDisplayString(column.serviceplans_name), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ], 544)
      ]),
      _createElementVNode("div", _hoisted_47, [
        (_ctx.permissions.some((evt) => evt.id === 4))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: {"margin-left":"0px !important","cursor":"pointer"},
              class: "nuevo-servicio",
              onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showPopupPrincipal = true))
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t("message.home.btn2")), 1),
              _cache[27] || (_cache[27] = _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "img-vector"
                })
              ], -1))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_48, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.query) = $event)),
            class: "buscador",
            type: "text",
            placeholder: _ctx.$t('message.planta.search'),
            name: "",
            id: "",
            style: {"font-size":"16px"}
          }, null, 8, _hoisted_49), [
            [_vModelText, _ctx.query]
          ]),
          _cache[28] || (_cache[28] = _createElementVNode("button", { class: "circle" }, [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "img-search"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_50, [
            _createElementVNode("button", {
              onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.exportCSV($event)))
            }, _toDisplayString(_ctx.$t("message.export")), 1)
          ])
        ])
      ]),
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.filteredTerminals,
            paginator: "",
            rows: 10,
            ref: "dt",
            rowsPerPageOptions: [5, 10, 15],
            stripedRows: "",
            tableStyle: "min-width: 1300px"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myColumnTablet, (column, index) => {
                return (_openBlock(), _createBlock(_component_Column, {
                  class: "title-c",
                  key: 'column-' + index,
                  sortable: column.sort,
                  field: column.value,
                  header: _ctx.$t(column.ts),
                  style: 'color:#000000!important;text-align:center;'
                }, _createSlots({ _: 2 }, [
                  (column.childrem && column.name === 'Action')
                    ? {
                        name: "body",
                        fn: _withCtx(({ data }) => [
                          (data.terminals_statusCommercial === 1)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "menu-button",
                                onClick: _withModifiers(($event: any) => (
            data.show === true ? (data.show = false) : (data.show = true)
            ), ["prevent"])
                              }, _cache[29] || (_cache[29] = [
                                _createElementVNode("img", {
                                  src: _imports_3,
                                  alt: "burguer-menu"
                                }, null, -1)
                              ]), 8, _hoisted_51))
                            : _createCommentVNode("", true),
                          (data.terminals_statusCommercial === 1)
                            ? (_openBlock(), _createElementBlock("form", {
                                key: 1,
                                onMouseleave: ($event: any) => (data.show = false)
                              }, [
                                _createVNode(_component_Menu, {
                                  id: "overlay_menu",
                                  ref_for: true,
                                  ref: "menu",
                                  style: {"position":"absolute"},
                                  popup: !data.show
                                }, {
                                  start: _withCtx(() => [
                                    (_ctx.permissions.some((evt) => evt.id === 12))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          onClick: ($event: any) => {
                  _ctx.showPopupDrowComisionamiento = true;
                _ctx.terminalSelect(data);
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_54, [
                                            _createElementVNode("div", _hoisted_55, [
                                              _cache[30] || (_cache[30] = _createElementVNode("img", {
                                                src: _imports_4,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t("message.planta.op1")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_53))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 19))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 1,
                                          onClick: ($event: any) => {
                  _ctx.terminalSelect(data);
                _ctx.validUpdate(data);
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_58, [
                                            _createElementVNode("div", _hoisted_59, [
                                              _cache[31] || (_cache[31] = _createElementVNode("img", {
                                                src: _imports_4,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t("message.planta.op2")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_57))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 8))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 2,
                                          onClick: ($event: any) => {
                  _ctx.terminalSelect(data);
                _ctx.showPopupAddress = true;
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_62, [
                                            _createElementVNode("div", _hoisted_63, [
                                              _cache[32] || (_cache[32] = _createElementVNode("img", {
                                                src: _imports_4,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.$t("message.planta.op3")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_61))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 20))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 3,
                                          onClick: ($event: any) => {
                  _ctx.terminalSelect(data);
                _ctx.showPopupDrowSus = true;
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_66, [
                                            _createElementVNode("div", _hoisted_67, [
                                              _cache[33] || (_cache[33] = _createElementVNode("img", {
                                                src: _imports_5,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.$t("message.planta.op4")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_65))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 15))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 4,
                                          onClick: ($event: any) => {
                  _ctx.terminalSelect(data);
                _ctx.showPopupDrowBaja = true;
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_70, [
                                            _createElementVNode("div", _hoisted_71, [
                                              _cache[34] || (_cache[34] = _createElementVNode("img", {
                                                src: _imports_6,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.$t("message.planta.op5")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_69))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 16))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 5,
                                          onClick: ($event: any) => {
                  _ctx.showPopupDrowSoporte = true;
                _ctx.terminalSelect(data);
                data.show = false;
                },
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_74, [
                                            _createElementVNode("div", _hoisted_75, [
                                              _cache[35] || (_cache[35] = _createElementVNode("img", {
                                                src: _imports_7,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_76, _toDisplayString(_ctx.$t("message.planta.op6")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_73))
                                      : _createCommentVNode("", true),
                                    (_ctx.permissions.some((evt) => evt.id === 17))
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 6,
                                          onClick: _withModifiers(($event: any) => (_ctx.gotografana(data)), ["prevent"]),
                                          class: "w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                                        }, [
                                          _createVNode(_component_Avatar, {
                                            image: "https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png",
                                            class: "mr-2",
                                            shape: "circle"
                                          }),
                                          _createElementVNode("div", _hoisted_78, [
                                            _createElementVNode("div", _hoisted_79, [
                                              _cache[36] || (_cache[36] = _createElementVNode("img", {
                                                src: _imports_8,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.$t("message.planta.op7")), 1)
                                            ])
                                          ])
                                        ], 8, _hoisted_77))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1032, ["popup"])
                              ], 40, _hoisted_52))
                            : _createCommentVNode("", true)
                        ]),
                        key: "0"
                      }
                    : (column.childrem && column.name === 'Estado Comercial')
                      ? {
                          name: "body",
                          fn: _withCtx(({ data }) => [
                            _createElementVNode("button", {
                              class: _normalizeClass(data.terminals_statusCommercial === 1
            ? 'btn-active'
            : 'btn-inactive'
            )
                            }, _toDisplayString(data.terminals_statusCommercial === 1 ? _ctx.$t("message.planta.m02") : _ctx.$t("message.planta.m03")), 3)
                          ]),
                          key: "1"
                        }
                      : (column.childrem && column.name === 'Estado Tecnico')
                        ? {
                            name: "body",
                            fn: _withCtx(({ data }) => [
                              _createElementVNode("button", {
                                class: _normalizeClass(data.terminals_statusTechnic === 1 ? 'btn-active' : 'btn-inactive'
            )
                              }, _toDisplayString(data.terminals_statusTechnic === 1 ? _ctx.$t("message.planta.m02") : _ctx.$t("message.planta.m03")), 3)
                            ]),
                            key: "2"
                          }
                        : (column.childrem && column.name === 'Ultima Conexión')
                          ? {
                              name: "body",
                              fn: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(String(data.billingConsumption_lastDailyDate).substring(0, 10)), 1)
                              ]),
                              key: "3"
                            }
                          : (column.childrem && column.name === 'Site ID')
                            ? {
                                name: "body",
                                fn: _withCtx(({ data }) => [
                                  _createTextVNode(_toDisplayString(String(data.terminals_nickname).substring(0, 20)), 1)
                                ]),
                                key: "4"
                              }
                            : (column.childrem && column.name === 'Dirección')
                              ? {
                                  name: "body",
                                  fn: _withCtx(({ data }) => [
                                    _createTextVNode(_toDisplayString(String(data.directions_formattedAddress).substring(0, 40)), 1)
                                  ]),
                                  key: "5"
                                }
                              : undefined
                ]), 1032, ["sortable", "field", "header"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_81, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"50px","height":"50px"},
              strokeWidth: "8",
              fill: "transparent",
              animationDuration: ".5s",
              "aria-label": "Custom ProgressSpinner"
            })
          ]))
    ])
  ], 64))
}