import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'
import _imports_1 from '@/assets/Vector-servicio.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_3 = { class: "group" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "group" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "group" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "group" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "btn-content" }
const _hoisted_12 = {
  class: "btn-submit-address",
  type: "submit"
}
const _hoisted_13 = {
  key: 0,
  style: {"margin":"20px 0px"}
}
const _hoisted_14 = {
  key: 0,
  class: "group"
}
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = {
  key: 1,
  class: "group"
}
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = {
  key: 2,
  class: "group"
}
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = {
  key: 3,
  class: "btn-content"
}
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { style: {"display":"flex","justify-content":"space-evenly","width":"90%","margin":"auto","align-items":"center"} }
const _hoisted_23 = {
  key: 4,
  class: "spinner-content2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Map = _resolveComponent("Map")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          style: {"cursor":"pointer","margin-top":"-1rem"},
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("message.planta.f18")) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("form", {
          class: "popup-form",
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.verificarAddress()), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              disabled: "",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.terminalSelected.directions_formattedAddress) = $event)),
              class: "custom-input",
              type: "text",
              placeholder: _ctx.$t('message.planta.c11'),
              style: {"padding-left":"0.5rem"}
            }, null, 8, _hoisted_4), [
              [_vModelText, _ctx.terminalSelected.directions_formattedAddress]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              disabled: "",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.terminalSelected.directions_latitude) = $event)),
              class: "custom-input",
              type: "text",
              placeholder: _ctx.$t('message.planta.f16'),
              style: {"padding-left":"0.5rem"}
            }, null, 8, _hoisted_6), [
              [_vModelText, _ctx.terminalSelected.directions_latitude]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              disabled: "",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.terminalSelected.directions_longitude) = $event)),
              class: "custom-input",
              type: "text",
              placeholder: _ctx.$t('message.planta.f16'),
              style: {"padding-left":"0.5rem"}
            }, null, 8, _hoisted_8), [
              [_vModelText, _ctx.terminalSelected.directions_longitude]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              required: "",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newService.direction) = $event)),
              class: "custom-input",
              type: "text",
              placeholder: _ctx.$t('message.planta.f17'),
              style: {"padding-left":"0.5rem"}
            }, null, 8, _hoisted_10), [
              [_vModelText, _ctx.newService.direction]
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.coordenate.length > 0
                ? _ctx.$t("message.planta.f3")
                : _ctx.$t("message.planta.f4")), 1)
          ])
        ], 32)
      ]),
      (_ctx.validAddress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_Map, {
              height: 200,
              width: 320
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.validAddress)
        ? (_openBlock(), _createElementBlock("form", {
            key: 1,
            class: "popup-form",
            onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.changeAddress()), ["prevent"]))
          }, [
            (_ctx.newData.params)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _withDirectives(_createElementVNode("input", {
                    disabled: "",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newData.params.regionCode) = $event)),
                    class: "custom-input",
                    type: "text",
                    placeholder: _ctx.$t('message.planta.c11'),
                    style: {"padding-left":"0.5rem"}
                  }, null, 8, _hoisted_15), [
                    [_vModelText, _ctx.newData.params.regionCode]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.newData.params)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    disabled: "",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newData.params.latitude) = $event)),
                    class: "custom-input",
                    type: "text",
                    placeholder: _ctx.$t('message.planta.f16'),
                    style: {"padding-left":"0.5rem"}
                  }, null, 8, _hoisted_17), [
                    [_vModelText, _ctx.newData.params.latitude]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.newData.params)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _withDirectives(_createElementVNode("input", {
                    disabled: "",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newData.params.longitude) = $event)),
                    class: "custom-input",
                    type: "text",
                    placeholder: _ctx.$t('message.planta.f16'),
                    style: {"padding-left":"0.5rem"}
                  }, null, 8, _hoisted_19), [
                    [_vModelText, _ctx.newData.params.longitude]
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isLoadingUpdateAddress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("button", {
                    disabled: _ctx.process,
                    class: "btn-submit",
                    type: "submit",
                    style: {"margin-left":"1rem"}
                  }, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("message.planta.f18")), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "img-vector"
                        })
                      ], -1))
                    ])
                  ], 8, _hoisted_21)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(_component_ProgressSpinner, {
                    style: {"width":"50px","height":"50px"},
                    strokeWidth: "8",
                    fill: "transparent",
                    animationDuration: ".5s",
                    "aria-label": "Custom ProgressSpinner"
                  })
                ]))
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}